import React, { HTMLAttributes } from 'react'
import styles from '../blog-post.module.scss'
import { formatDate } from 'Utils/tools/naming'
import { IBlogData } from './blog.interfaces'

interface IBlogHeader extends HTMLAttributes<HTMLElement> {
    post: IBlogData;
}
export default function BlogHeader({ post }: IBlogHeader) {
    return (
        <>
            <h2 className={styles.blogPostTitle}>{post.frontmatter.title}</h2>
            <div className={styles.blogPostAttributes}>
                <div className={styles.date}>📅 Published {formatDate(post.frontmatter.date)}</div>
                {
                    post.frontmatter.edited &&
                    <div className={styles.edited}>{'('}Edited {formatDate(post.frontmatter.date)}{')'}</div>
                }
                <div className={styles.time}>⏱ {post.timeToRead} minute read</div>
                <div className={styles.author}>📝 By {post.frontmatter.author}</div>
            </div>
            <hr />
        </>
    )
}
