import React, { HTMLAttributes, useState } from 'react'
import styles from '../blog-post.module.scss'
import { IBlogData, IBlogListingData } from './blog.interfaces'
import { Link } from 'gatsby'
import vars from 'SCSS/config.scss'

enum SortDirection {
    NONE=0,
    ASC=1,
    DSC=-1
}
interface IBlogTags extends HTMLAttributes<HTMLElement> {
    post: IBlogData | IBlogListingData['node'];
    colors?: boolean;
    sortDir?: SortDirection;
}
export default function BlogTags({ post, colors=false, sortDir=SortDirection.NONE, className, ...etc }: IBlogTags) {
    const { tags } = post.frontmatter
    return (
        <ul className={styles.tagsList}>
        {
            tags && tags.length > 0 &&
                tags
                .sort((a, b) => {
                    switch (sortDir) {
                        case SortDirection.NONE:
                            return 0;
                        case SortDirection.ASC:
                            return a.length - b.length;
                        case SortDirection.DSC:
                            return b.length - a.length;
                        default:
                            return 0;
                    }
                })
                .map((tag, index) => (
                    <li key={index}><Tag tag={tag} className={styles.tag} colors /></li>
                ))

        }
        </ul>
    )
}

const BlogTagColor: {[key: string]: React.CSSProperties} = {
	react: {
        backgroundColor: '#81D8F7',
    },
    tutorial: {
        backgroundColor: '#fcba03'
    },
    webdev: {
        backgroundColor: '#ee92f0'
    },
    css: {
        backgroundColor: '#f4bff5'
    },
    git: {
        backgroundColor: '#DF5B40'
    },
    blog: {
        backgroundColor: vars.bFill
    },
    learning: {
        backgroundColor: '#edcc93'
    }
}

interface ITag extends HTMLAttributes<HTMLElement> {
    tag: string;
    handleClick?(tag: string):void;
    colors?: boolean;
}
export function Tag({tag, handleClick=()=>{}, className, colors, ...etc}: ITag) {
    let href = `[tags:${tag}]`
    const [color, setColor] = useState(
        colors && BlogTagColor[tag.toLowerCase()] ? BlogTagColor[tag.toLowerCase()] : {}
    );
    return (
        <Link to={`/blog?q=${encodeURI(href)}`} onClick={() => handleClick(tag)} className={className} style={color} data-tag={tag} {...etc}>{tag}</Link>
    )
}