import React, { HTMLAttributes } from 'react'
import { Link } from 'gatsby'
import styles from '../blog-post.module.scss'
import { IBlogListingData } from './blog.interfaces'
import { formatDate } from '../../../utils/tools/naming';
import BlogTags, { Tag } from './BlogTags';

interface IBlogResult extends HTMLAttributes<HTMLElement> {
    post: IBlogListingData;
    onTagClick?(tag: string): void;
}
export default function BlogResult({ post, onTagClick=()=>{} }: IBlogResult) {
    const { node } = post;
    const { frontmatter: details } = node;
    return (
        <li className={`blog-result`}>
            <article>
                <Link to={details.path} className={`blog-result-title`}><h1>{details.title}</h1></Link>
                <div>
                    <div className={`blog-result-attributes`}>
                        <div className={`blog-result-attributes-date`}>📅 {formatDate(details.date)}</div>
                        <div className={`blog-result-attributes-time`}>⏱ {node.timeToRead} minute read</div>
                        <div className={`blog-result-attributes-author`}>📝 {details.author}</div>
                    </div>
                </div>
                <div className={`blog-result-excerpt`}>
                    {
                        details.description || node.excerpt
                    }
                </div>
                {
                    details.tags && details.tags.length > 0 && (
                        <ul className={`blog-result-tags`}>
                            {
                                details.tags.map((tag, index) => (
                                    <li key={index}>
                                        <Tag 
                                            tag={tag} 
                                            colors 
                                            className={`blog-result-tags-tag`} 
                                            handleClick={onTagClick} 
                                        />
                                    </li>
                                ))
                            }
                        </ul>
                    )
                }
            </article>
        </li>
    )
}
