import React, { HTMLAttributes, useState } from 'react'
import 'SCSS/blog.scss'
import { IBlogListingData, IBlogFilter } from './blog.interfaces'
import { formatDate } from '../../../utils/tools/naming';

interface IBlogSearch extends HTMLAttributes<HTMLFormElement> {
	posts: IBlogListingData[];
	value?: string;
	handleChange?(results: IBlogFilter): void;
	handleSubmit?(results: IBlogFilter): void;
}
export default function BlogSearch({ posts=[], handleChange, handleSubmit, value, className, ...etc }: IBlogSearch) {

	const [search, setSearch] = useState<IBlogFilter>({
		filtered: posts,
		query: ''
	});
	function edit(e: React.ChangeEvent<HTMLInputElement>) {
		let filtered = filterResults(e.target.value, posts)
		setSearch(filtered);
		handleChange && handleChange(filtered);
	}

	function submit(e: React.ChangeEvent<HTMLFormElement>) {
		e.preventDefault();
		handleSubmit && handleSubmit(search);
	}

	return (
		<form className={`blog-form ${className}`} {...etc} onSubmit={submit}>
			<input
				className={`blog-form-text`}
				type='search'
				aria-label="Search"
				placeholder="Search posts..."
				onChange={edit}
				value={value}
			/>
			<input
				className={`blog-form-button`}
				type='submit'
				value='Search'
			/>
		</form>
	)
}

interface ISearchField {
	[key: string]: {
		(q: string, post: IBlogListingData): boolean;
	}
}

export function filterResults(query: string, posts: IBlogListingData[]): IBlogFilter {
	// Working array of posts
	let results = [...posts];
	// Working query string 
	let _query = query.toLowerCase();
	// Original query string (lowercased)
	let ogQuery = `${_query}`;

	// === CHECK QUERY IS IN FIELD === //
	function simpleCheck(searchTerm: string, value: string): boolean {
		return typeof value !== 'undefined' && value !== null && value.toLowerCase().includes(searchTerm);
	}
	// === CHECK QUERY IS IN DESCRIPTION OR EXCERPT === //
	function descCheck(searchTerm: string, post: IBlogListingData): boolean {
		return (
			simpleCheck(searchTerm, post.node.excerpt) 
			|| simpleCheck(searchTerm, post.node.frontmatter.description)
		);
	}
	// === CHECK QUERY IS IN DATE === //
	function dateCheck(searchTerm: string, date: string): boolean {
		let dateStr = date && formatDate(date, true, false).replace(',', '');
		return typeof date !== 'undefined' && date !== null && dateStr.toLowerCase().includes(searchTerm)
	}
	// === CHECK QUERY IS IN TAGS === //
	function tagCheck(searchTerm: string, tags: string[]): boolean {
		let hasMatch = false;
		searchTerm.split(',').forEach(term => {
			tags?.forEach(tag => {
				if(tag.toLowerCase().includes(term)) {
					hasMatch = true;
				}
			})
		})
		return hasMatch;
	}

	// === SEARCH METHODS === //
	const searchFields: ISearchField = {
		author: (q: string, post: IBlogListingData): boolean => simpleCheck(q, post.node.frontmatter.author),
		title: (q: string, post: IBlogListingData): boolean => simpleCheck(q, post.node.frontmatter.title),
		description: (q: string, post: IBlogListingData): boolean => descCheck(q, post),
		desc: (q: string, post: IBlogListingData): boolean => descCheck(q, post),
		excerpt: (q: string, post: IBlogListingData): boolean => descCheck(q, post),
		date: (q: string, post: IBlogListingData): boolean => dateCheck(q, post.node.frontmatter.date),
		tags: (q: string, post: IBlogListingData): boolean => tagCheck(q, post.node.frontmatter.tags),
		tag: (q: string, post: IBlogListingData): boolean => tagCheck(q, post.node.frontmatter.tags),
	}

	// === REMOVE ANY SEARCH FIELDS === //
	let regex = /\[(\w+?):([\w,-]+)\]/g;
	_query = _query.replace(regex, (_match, key, val) => {
		results = results.filter(post => {
			if(searchFields[key]) {
				return searchFields[key](val, post);
			}
			return false
		});
		return '';
	})
	// === SEARCH WITH REMAINING TERMS === //
	results = results.filter(post => {
		const { excerpt } = post.node;
		const { title, author, date, description, tags } = post.node.frontmatter;
		
		let allowThrough = false;

		// === Test Title === //
		allowThrough = title && title.toLowerCase().includes(_query) || allowThrough;
		
		// === Test Excerpt === //
		allowThrough = excerpt && excerpt.toLowerCase().includes(_query) || allowThrough;

		// === Test Author === //
		allowThrough = author && author.toLowerCase().includes(_query) || allowThrough;

		// === Test Date === //
		let dateStr = date && formatDate(date, true, false).replace(',', '');
		allowThrough = date && dateStr.toLowerCase().includes(_query) || allowThrough;

		// === Test Desc === //
		allowThrough = description && description.toLowerCase().includes(_query) || allowThrough;

		// === Test Tags === //
		tags?.forEach(tag => {
			allowThrough = tag.toLowerCase().includes(_query) || allowThrough;
		})

		// === FILTER === //
		return allowThrough;
	});

	// === RETURN MATCHES === //
	return {
		filtered: results,
		query: ogQuery
	};
}