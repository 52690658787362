import React, { HTMLAttributes } from 'react'
import { graphql } from 'gatsby'
import styles from '../blog-post.module.scss'
import 'SCSS/blog-theme.scss'
import 'SCSS/blog.scss'
import { urlName } from '../../../utils/tools/naming'
import { DiscussionEmbed } from "disqus-react"
import BlogTemplate from './blog-template'
import BlogHeader from './BlogHeader'
import BlogTags from './BlogTags'
import { IBlogData } from './blog.interfaces'
import nextId from 'react-id-generator'

interface IBlogPage extends HTMLAttributes<HTMLElement> {
	data: {
        markdownRemark: IBlogData;
    }
}
export default function BlogPage({ data }: IBlogPage) {
    const { markdownRemark: post } = data

    let id = post ? post.id : nextId('post');
    let title = post && post.frontmatter ? post.frontmatter.title : 'Post';

    let disqusConfig = {
        shortname: 'ginovalente',
        config: { 
            identifier: id, 
            title: title,
        },
      }
    
	return (
        <BlogTemplate
            post={post}
            header={<BlogHeader post={post} />}
            content={
                post &&
                <div 
                    className={`${styles.blogPostContent} post`}
                    dangerouslySetInnerHTML={{
                        __html: loadFrontmatter(post.html, post)
                    }}
                />
            }
            tags={<BlogTags post={post} colors sortDir={-1} />}
            footer={
                <DiscussionEmbed className={styles.comments} {...disqusConfig} />
            }
        />
	)
}


function loadFrontmatter(html: string, blogData: IBlogData, headerOffset: number=2): string {
    let _html = `${html}`;

    // === REPLACE VARIABLES === //
    _html = _html.replace(/\\?{{(.+)}}/g, (match, blogVar: string) =>{
        if(match.charAt(0) === '\\') {
            return `{{${blogVar}}}`
        }
        let keys: string[] = blogVar.split('.');
        let currentData = blogData;
        for(let key of keys.slice(0, -1)) {
            currentData = currentData[key];
            if(typeof currentData === 'undefined') {
                return match;
            }
            
        }
        let replacement = currentData[keys.slice(-1)[0]];
        if (typeof replacement !== 'undefined') {
            return replacement;
        }
            return match;
    })

    // === REPLACE HEADERS === //
    _html = _html.replace(/<h(\d)>(.*?)<\/h(\d)>/g, (match, digit1, content: string, digit2) => {

        // Offset header values to match page
        let num = parseInt(digit1);
        if(num < 6 - headerOffset) {
            num += headerOffset;
        }

        // Get the anchor point name
        let name = urlName(content.toLowerCase(), true);

        return `<h${num}><a name="${name}">${content}</a></h${num}>`;
    })

    // === REPLACE IMAGES === //
    _html = _html.replace(/<post-img((.|\s)*?)>((.|\s)*?)<\/post-img>/g, (_match, classes: string, _2, inner) => {
        classes = classes.split(/\s+?/)
            .map(_class => _class && `post-img-${_class}`).join(' ');
        return `<div class="post-img ${classes}">${inner}</div>`;
    })

    return _html;
}

export const pageQuery = graphql`
	query BlogPostByPath($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            id
            excerpt
			frontmatter {
				path
				title
				author
				date
				tags
				edited
                description
                toc
			}
            timeToRead
            wordCount {
                words
            }
            tableOfContents(absolute: false)
		}
    }
`
