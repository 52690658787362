import React, { HTMLAttributes } from 'react'
import { Link } from 'gatsby'
import styles from '../blog-post.module.scss'
import { padWith } from 'Utils/tools/naming'


interface IBlogListing extends HTMLAttributes<HTMLLIElement> {
    title: string;
    path: string;
    index: number;
    isSelected?: boolean;
    scroll?: boolean;
}
export default function BlogListing({title, path, index, isSelected, scroll}: IBlogListing) {
    return (
        <li>
            <Link to={`${path}${scroll ? `#blog-post` : ''}`} className={styles.channel} state={{scroll: false}} title={title}>
                <div className={styles.channelName}>{title}</div>
                <div className={styles.channelNumber}>{padWith(index, '0', 3)}</div>
                <div className={styles.channelIndicator}>
                    <div className={isSelected ? styles.buttonPressed : styles.button} />
                </div>
            </Link>
        </li>
    )
}
