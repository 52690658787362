import React, { HTMLAttributes } from 'react'
import styles from '../blog-post.module.scss';

interface IDial extends HTMLAttributes<HTMLElement> {
    svg: any;
    alt: string;
    href: string;
}
export default function Dial({svg, alt, href}: IDial) {
    let DialImage = svg;
    return (
        <a className={styles.dial} href={href} title={alt}>
            <DialImage role='img' />
            <div />
        </a>
    )
}
