/**
 * Parses a URL or query for key/value pairs.
 *
 * @param url The URL or query to parse
 */
export function parameterize(url: string): { [key: string]: string } {
	let paramStart = url.indexOf('?')
	if (paramStart === -1) {
		// No parameters
		return {}
	}

	let paramString: string = url.substr(paramStart)

	let parameters: { [key: string]: string } = {}

	// Stupid IE, Safari, & FireFox for forcing no lookbehinds...
	let keys = paramString.match(/[?&]\w*(?==)/g)
	let vals = paramString.match(/=[\w%:]+/g)

	if (keys !== null && vals !== null && typeof vals !== 'undefined') {
		keys.map(
			(key, index) =>
				(parameters[key.substr(1)] = vals![index].substr(
					1
				))
		)
	}

	return parameters
}

/**
 * Returns an updated URL or query string with the given parameters.
 *
 * @param url The URL or query to parse
 * @param changes The key/value pair to update
 * @param options Options controlling how url is updated
 */
export function updateParameters(
	url: string,
	changes: { [key: string]: any },
	options = { convertBool: true, dropNull: true }
) {
	let hasParams: boolean = url.indexOf('?') !== -1
	
	for (let key in changes) {
		let val: string = changes[key]
		
		let skipThis = false

		// Skip null values
		if (val === null && options.dropNull) {
			skipThis = true
		}

		// Skip empty strings
		if (val === '') {
			skipThis = true
		}

		// Convert booleans
		if (typeof val === 'boolean' && options.convertBool) {
			val = val ? '1' : '0'
		}

		// Convert to string
		val = `${val}`

		// Setup regex and replacement strings
		let regex: RegExp = new RegExp(`(&?)(${key}=\\w*)`)
		let replacement: string = `${key}=${val}`

		// IF: no params -> start with '?'
		if (!hasParams) {
			url += '?'
		}

		// === Add/Update Params === //
		if(skipThis) {
			url = url.replace(regex, '')
		} else if (url.match(regex)) {
			// IF: param exists -> replace it
			let match = url.match(regex);
			if(match) {
				let amp = match[1] ? '&' : ''
				url = url.replace(regex, `${amp}${replacement}`);
			}
		} else {
			// ELSE: add it (with an ampersand in neccessary)
			url += hasParams && !skipThis ? '&' : ''
			url += replacement
		}

		hasParams = true
	}

	url = url.replace(/&+/g, '&')
	url = url.replace('?&', '?')
	if(url.charAt(url.length - 1) === '?') {
		url = url.substr(0, url.length - 1)
	}

	return url
}