import React, { HTMLAttributes, useEffect, useState } from 'react'
import SEO from 'Components/seo'
import Navbar from 'Components/navbar'
import styles from '../blog-post.module.scss'
import Dial from './Dial'
import { formatDate } from '../../../utils/tools/naming'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import TwitterLogo from 'Assets/icons/icons8-twitter.svg'
import GithubLogo from 'Assets/icons/icons8-github.svg'
import BlogListing from './BlogListing'
import { IBlogData, IBlogListingEdges, IBlogListingData, IBlogFilter } from './blog.interfaces'
import BlogSearch, { filterResults } from './BlogSearch'
import BlogResult from './BlogResult'
import { Location } from '@reach/router'
import { parameterize } from '../../../utils/tools/parameterize'
import 'SCSS/blog-theme.scss'

type ILoc =  Location & {
    state: {
        filter: IBlogFilter;
    }
    search: string;
}
interface IBlogTemplate extends HTMLAttributes<HTMLElement> {
    post?: IBlogData;
    header?: JSX.Element;
    content?: JSX.Element
    tags?: JSX.Element;
    footer?: JSX.Element;
    location?: ILoc;
    description?: string;
    url?: string;
}
export default function BlogTemplate({ post, header, content, tags, footer, location, description, url }: IBlogTemplate) {

    const data: IBlogListingEdges = useStaticQuery(graphql`
        query BlogListings {
            allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}) {
                edges {
                    node {
                        excerpt
                        id
                        timeToRead
                        frontmatter {
                            author
                            date
                            tags
                            title
                            path
                            description
                        }
                    }
                }
            }
        }
    `)

    const { edges: posts } = data.allMarkdownRemark

    const limitIncrease = 100; //FIXME: Clicking an unloaded link hangs page
    const [menuLimit, setMenuLimit] = useState(limitIncrease);
    const [limit, setLimit] = useState(limitIncrease);
    const [filter, setFilter] = useState<IBlogFilter>({filtered: posts, query: ''});

    // === Fired On Each Keypress of Main-Search === //
    function onEdit(results: IBlogFilter) {
        setFilter(results);
    }
    // === Fired On Side-Search Submit === //
    function onSearch(results: IBlogFilter) {
        navigate(`/blog?q=${encodeURI(results.query)}`, {
            state: {
                filter: results,
            },
        });
        setFilter(results);
    }
    // === Loads Results from URL or Passed-State === //
    useEffect(() => {
        if(location && location.search) {
            let params = parameterize(location.search);
            setFilter(filterResults(decodeURI(params['q']), posts));
        } else if(location && location.state) {
            const { filter: filterData } = location.state;
            if(filterData) {
                setFilter({
                    filtered: filterData.filtered || [],
                    query: filterData.query || ''
                });
            }
        }
    }, [location]);

    // === Reloads Results on Tag Click === //
    function tagClick(tag: string) {
        setFilter(filterResults(`[tags:${tag}]`, posts));
    }

    // === Dynamic Loading === //
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        
        let pageMain = document.querySelector('.page-main') as HTMLElement
        let menuElt = document.querySelector(`.${styles.channelSelectContainer}`) as HTMLElement

        function checkScroll(e: Event) {
            let mainHitBottom = pageMain.scrollHeight - pageMain.scrollTop - pageMain.clientHeight < pageMain.clientHeight / 2;
            if(mainHitBottom && limit < filter.filtered.length) {
                setLimit(limit + limitIncrease)
            }
            let menuHitBottom = menuElt.scrollHeight - menuElt.scrollTop - menuElt.clientHeight < 1;
            if(menuHitBottom && menuLimit < posts.length) {
                setMenuLimit(menuLimit + limitIncrease);
            }
            
        }

        if(menuElt.scrollHeight === menuElt.clientHeight && limit < posts.length) {
            setMenuLimit(menuLimit + limitIncrease);
        }

        if(pageMain) {
            pageMain.addEventListener(
                'scroll',
                checkScroll,
                false
            )
        }

        return () => {
            let elt = document.querySelector('.page-main')

            if(elt) {
                elt.removeEventListener(
                    'scroll',
                    checkScroll,
                    false
                )
            }
        }
    }, [limit, menuLimit])
    // /== Dynamic Loading  === //
    
    // === MOBILE MENU === //
    function toggleMenu(_e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        let menu = document.querySelector(`.${styles.blogMenu}`) as HTMLElement
        menu.classList.toggle(styles.open);
    }
    // /== MOBILE MENU === //

    const metaUrl = url ? url : (post && post.frontmatter ? post?.frontmatter.path : '');

    return (
        <div className='page'>
            <SEO title="Blog" url={metaUrl} description={description || post && ((post.frontmatter && post?.frontmatter.description) || post?.excerpt)} />
            <Navbar from="/blog" />
            <div className={`page-main ${styles.main}`} style={{overflowX: 'hidden'}}>
                <div className={`${styles.pageTitle} blog-page-title`}>
                    <div> you're watching </div>
                    <h1 >GinoTV!</h1>
                </div>
                <div className={`${styles.tvContainer} blog-tv-container`}>
                    <div className={styles.antennae} />
                    <div className={styles.blogPostContainer}>
                        <article className={styles.blogPost} id="blog-post">
                            {
                                header ? header : (
                                    <div className={`blog-default-header`}>
                                        <BlogSearch posts={posts} handleChange={onEdit} value={filter.query} />
                                    </div>
                                )
                            }
                            {
                                content ? content : (
                                    <ol className={`blog-result-container`}>
                                        {
                                            filter.filtered.length > 0 ? (
                                                filter.filtered
                                                .slice(0, limit).map(result => (
                                                    <BlogResult 
                                                        post={result} 
                                                        key={result.node.id} 
                                                        onTagClick={tagClick} 
                                                    />
                                                ))
                                            ) : (
                                                <div className={`no-results-title`}>
                                                    <p>No Results :{'('}</p>
                                                </div>
                                            )
                                        }
                                    </ol>
                                )
                            }
                        </article>
                        <aside className={`${styles.blogMenu} blog-menu`}>
                            <div className={styles.menuTab} onClick={toggleMenu}>Menu</div>
                            <div className={styles.blogMenuContent}>
                                <nav className={styles.channelSelectContainer}>
                                    <ul>
                                        {
                                            posts.slice(0, menuLimit).map((listing, index) => {
                                                let listingPath = listing.node.frontmatter.path
                                                return (
                                                    <BlogListing
                                                        key={listing.node.id}
                                                        title={listing.node.frontmatter.title}
                                                        path={listing.node.frontmatter.path}
                                                        index={posts.length - index}
                                                        isSelected={post && listingPath === post.frontmatter.path}
                                                        scroll={scroll}
                                                    />
                                                )
                                            })
                                        }
                                    </ul>
                                </nav>
                                <div className={styles.dials}>
                                        <Dial svg={TwitterLogo} href={'https://twitter.com/TheGinoValente'} alt={'Follow my Twitter!'} />
                                        <Dial svg={GithubLogo} href={'https://www.github.com/MrGVSV'} alt={'Check out my Github!'} />
                                </div>
                                <div className={styles.blogSearchContainer}>
                                    <BlogSearch posts={posts} handleSubmit={onSearch} className={styles.blogSearchForm} />
                                </div>
                            </div>
                            <div className={styles.tags}>
                                {tags}
                            </div>
                        </aside>
                    </div>
                </div>
                {footer}
                <span className={styles.iconAttribution}>Icons courtesy of <a href="https://icons8.com/">icons8.com</a></span>
            </div>
		</div>
    )
}